import React, { SFC } from "react";
import { FaTwitter, FaInstagram } from "react-icons/fa";
import { StaticQuery, graphql } from "gatsby";
import "./style.scss";
import { Link } from "gatsby";

const Footer: SFC<{}> = ({ }) => (
  <StaticQuery
    query={graphql`
      query SocialQuery {
        site {
          siteMetadata {
            twitter
            instagram
          }
        }
      }
    `}
    render={data => (
      <footer className="flex flex-col bg-gray-200">
        <div className="flex flex-row flex-grow flex-wrap justify-center">

        <div className="mx-6 flex flex-col">
            <h3 className="text-sm uppercase">About</h3>
            {/*<Link to="/about/">About Us</Link> */}
            <Link to="/terms/">Terms of Service</Link>
            <Link to="/privacy/">Privacy Policy</Link>
            {/*<Link to="/faq/">FAQ</Link>*/}
            {/*<Link to="/jobs/">Jobs</Link>
                <Link to="/involved/">Get Involved</Link>*/}
          </div>
          {/*<div className="mx-6 flex flex-col">
            <h3 className="text-sm uppercase">Science</h3>
            <Link to="/publications/"> Publications </Link>
          </div>*/}
          {/*
          <div className="flex flex-col mx-6">
            <h3 className="text-sm uppercase">Media</h3>
            <Link to="/blog/"> Blog </Link>
            <Link to="/presskit/">Press Kit</Link>
            <Link to="/coverage/"></Link>Media Coverage (coming soon)
                <Link to="/news/"></Link>News (coming soon)
          </div>*/}
          <div className="flex flex-col mx-6">
            <h3 className="text-sm uppercase">Social</h3>
            <div className="flex">
              <a href={data.site.siteMetadata.twitter}><FaTwitter /></a>
              <div className="ml-2"><a href={data.site.siteMetadata.twitter}>Twitter</a></div>
            </div>
            <div className="flex">
              <a href={data.site.siteMetadata.instagram}><FaInstagram /></a>
              <div className="ml-2"><a href={data.site.siteMetadata.instagram}>Instagram</a></div>
            </div>
          </div>
        </div>
        <div className="flex justify-center flex-grow mt-3">
          <div className="flex">© Infinity Biosystems Corporation, 2019. All Rights Reserved.</div>
        </div>
      </footer>
    )}
  />
);

export default Footer;
