import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Helmet from "react-helmet";

export default () => (
  <StaticQuery
    query={graphql`
      query helmetQuery {
        site {
          siteMetadata {
            title
            author
            imageUrl
            description
            keywords
          }
        }
      }
    `}
    render={data => (
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
        <meta name="description" content={data.site.siteMetadata.description} />
        <meta name="keywords" content={data.site.siteMetadata.keywords} />
        <title>{data.site.siteMetadata.title}</title>
        <html lang="en" />
        {/* Google / Search Engine Meta Tags */}
        <meta name="subject" content={data.site.siteMetadata.subject} />
        <meta name="copyright" content={data.site.siteMetadata.copyright} />
        <meta name="language" content={data.site.siteMetadata.language} />
        <meta name="robots" content={data.site.siteMetadata.robots} />
        <meta name="revised" content={data.site.siteMetadata.revised} />
        <meta name="abstract" content={data.site.siteMetadata.abstract} />
        <meta name="topic" content={data.site.siteMetadata.topic} />
        <meta name="summary" content={data.site.siteMetadata.summary} />
        <meta name="author" content={data.site.siteMetadata.author} />
        <meta name="reply-to" content={data.site.siteMetadata.replyAddress} />
        <meta name="owner" content={data.site.siteMetadata.owner} />
        <meta name="url" content={data.site.siteMetadata.url} />
        <meta
          name="identifier-URL"
          content={data.site.siteMetadata.identifierUrl}
        />
        <meta name="category" content={data.site.siteMetadata.category} />
        <meta name="coverage" content={data.site.siteMetadata.coverage} />
        <meta
          name="distribution"
          content={data.site.siteMetadata.distribution}
        />
        <meta name="rating" content={data.site.siteMetadata.rating} />
        <meta
          name="revisit-after"
          content={data.site.siteMetadata.revisitAfter}
        />
        <meta http-equiv="Expires" content={data.site.siteMetadata.expires} />
        <meta http-equiv="Pragma" content={data.site.siteMetadata.pragma} />
        <meta
          http-equiv="Cache-Control"
          content={data.site.siteMetadata.cacheControl}
        />
        {/* Open Graph*/}
        <meta name="og:title" content={data.site.siteMetadata.title} />
        <meta name="og:type" content={data.site.siteMetadata.ogType} />
        <meta name="og:url" content={data.site.siteMetadata.url} />
        <meta name="og:image" content={data.site.siteMetadata.imageUrl} />
        <meta name="og:site_name" content={data.site.siteMetadata.siteName} />
        <meta
          name="og:description"
          content={data.site.siteMetadata.description}
        />
        <meta name="fb:page_id" content={data.site.siteMetadata.fbId} />
        <meta name="og:email" content={data.site.siteMetadata.replyAddress} />
        {/* <!-- Twitter summary card with large image must be at least 280x150px --> */}
        <meta name="twitter:card" content={data.site.siteMetadata.imageUrl} />
        <meta name="twitter:site" content={data.site.siteMetadata.siteName} />
        <meta name="twitter:title" content={data.site.siteMetadata.title} />
        <meta
          name="twitter:description"
          content={data.site.siteMetadata.descriptionTwitter}
        />
        <meta name="twitter:creator" content={data.site.siteMetadata.twitterHandle} />
        <meta
          name="twitter:image:src"
          content={data.site.siteMetadata.imageUrl}
        />
        <meta property="fb:admins" content={data.site.siteMetadata.facebookId} />
        
      </Helmet>
    )}
  />
);
